import { functions } from './index';

export const setFirestoreOrganization = async(data:{email: string, ownerName: string, organizationCode: string, organizationName: string, companyName: string, serviceType: string, type: string, startPaymentDate: string}) => {
  const response = await functions.httpsCallable('onCall-createNewOrganization')({
    email: data.email,
    ownerName: data.ownerName,
    organizationCode: data.organizationCode,
    organizationName: data.organizationName,
    companyName: data.companyName,
    serviceType: data.serviceType,
    type: 'owner',
    startPaymentDate: data.startPaymentDate,
  }).catch((err: Error) => {
    console.log(`err setFirestoreOrganization: ${err}`);
    throw err;
  });
  return response
}

export const updateMemberAndRoleTypes = async() => {
  const response = await functions.httpsCallable('onCall-updateMemberAndRoleTypes')().catch((err: Error) => {
    console.log(`err updateMemberAndRoleTypes: ${err}`);
    throw err;
  });
  return response
}
export const setMultiServiceType = async() => {
  const response = await functions.httpsCallable('onCall-setMultiServiceType')().catch((err: Error) => {
    console.log(`err setMultiServiceType: ${err}`);
    throw err;
  });
  return response
}


export const copyUserData = async(organizationId: string, numberOfUser: number, selectedUserId: string) => {
  const response = await functions.httpsCallable('onCall-copyUserData')({
    organizationId: organizationId,
    numberOfUser: numberOfUser,
    selectedUserId: selectedUserId,
  }).catch((err: Error) => {
    console.log(`err copyUserData: ${err}`);
    throw err;
  });
  return response
}

export const uploadCsv = async(organizationId: string, serviceType: 'aType' | 'bType', csvFile: any, date: string) => {
  const response = await functions.httpsCallable('onCall-uploadUsageRecordByCsv')({
    organizationId: organizationId,
    serviceType: serviceType,
    csvFile: csvFile,
    date: date,
  }).catch((err: Error) => {
    console.log(`err copyUserData: ${err}`);
    throw err;
  });
  return response
}

export const updateMember = async(organizationId: string, email: string, name: string, type: 'owner' | 'manager' | 'operator', serviceType: string, membarId?: string) => {
  const response = await functions.httpsCallable('onCall-updateMember')({
    organizationId: organizationId,
    memberId: membarId,
    email: email,
    name: name,
    type: type,
    serviceType: serviceType,
  }).catch((err: Error) => {
    throw err;
  });
  return response.data;
}

export const deleteMember = async(organizationId: string, memberIds: string[]) => {
  const response = await functions.httpsCallable('onCall-deleteMember')({
    organizationId: organizationId,
    memberIds: memberIds,
  }).catch((err: Error) => {
    throw err;
  });
  return response.data;
}

export const updateOrganizationStatus = async() => {
  const response = await functions.httpsCallable('onCall-updateOrganizationStatus')().catch((err: Error) => {
    throw err;
  });
  return response.data;
}

export const updateUserServiceType = async() => {
  const response = await functions.httpsCallable('onCall-updateUserServiceType')().catch((err: Error) => {
    throw err;
  });
  return response.data;
}

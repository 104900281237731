import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { BasicButton } from "../atoms/Button";
import { SelectInput } from "../atoms/SelectInput";
import { Caption, SubTitle } from "../atoms/Text"
import { SingleTextInput } from "../molecules/TextField";
import { naturalNumberRegex } from "../../utils/Validation";
import { useTypedSelector } from "../../redux";
import { copyUserData, updateOrganizationStatus, updateUserServiceType, uploadCsv } from "../../firebase/functions";
import { firestore } from "../../firebase";
import { StyledFullPaper } from "../atoms/Paper"
import dayjs from "../../lib/dayjs";
import { DatePicker } from "../atoms/DatePicker";

const items = [
  {value:'aType', label: 'A型'},
  {value:'bType', label: 'B型'},
]

export const DevArea = () => {
  const [numberOfUser, setNumberOfUser] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [fileState, setFileState] = useState<any>();
  const [userList, setUserList] = useState<Array<{label: string, value: string}>>([]);
  const [date, setDate] = useState<string>(dayjs().format('YYYY-MM'));
  const [serviceType, setServiceType] = useState<'aType'|'bType'>('aType');
  const organizations = useTypedSelector((state) => state.organizations);
  const typeBOrganization = useTypedSelector((state) => state.typeBOrganizations);
  
  const selectItems = useMemo(() => {
    if ((serviceType === 'aType' && organizations) || (serviceType === 'bType' && typeBOrganization)) {
      const selectedOrganizations = serviceType === 'aType' ? organizations : typeBOrganization;
      return selectedOrganizations.map((doc) => {
        return {
          value: doc.organizationId,
          label: doc.companyName ? doc.companyName : '名称未設定'
        }
      })
    } else {
      return [];
    }
  }, [organizations, serviceType, typeBOrganization]);

  const onClickCopyButton = async() => {
    console.log('onClickCopyButton')
    if(!numberOfUser && !organizationId){
      window.alert('入力もれがあります');
      return
    }
    if(!naturalNumberRegex.test(numberOfUser)){
      window.alert('数字を入力してください');
      return
    }
    setIsDisabled(true);
    console.log(date)
    await copyUserData(organizationId, Number(numberOfUser), selectedUserId).catch((err: Error) => {
      window.alert(`エラーが発生しました: ${err}`);
      setIsDisabled(false);
      return;
    });
    setIsDisabled(false);
  }
  const getUsers = async(organizationId: string) => {
    const list: Array<{label: string, value: string}> = [];
    const usersRef = await firestore.collection('users').where('organizationId', '==', organizationId).get();
    if(usersRef.empty) {
      setUserList([]);
    };
    usersRef.docs.forEach((doc) => {
      list.push({
        label: doc.data()?.nameKanji ? doc.data()?.nameKanji : '名称未設定',
        value: doc.id
      });
    });
    setUserList(list);
  }
  useEffect(() => {
    if(organizationId){
      getUsers(organizationId)
    }
  },[organizationId]);

  const onUploadFile = (e:ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ?  e.target.files[0] : null;
    if(!file) return;
    var reader = new FileReader();
    reader.onload = function(event) {
      if(!event.target) return
      var data = event.target.result;
      setFileState(data)
    };
    reader.readAsDataURL(file)
  }
  const upload = async() => {
    if(!fileState) return;
    uploadCsv(organizationId, serviceType, fileState, date).then(() => {
      window.alert('完了');
    }).catch((err: Error) => {
      window.alert(`err: ${err.message}`);
      return;
    });
  }
  const updateOrgStatus = async() => {
    const res = await updateOrganizationStatus().catch((err: Error) => {
      window.alert(`err: ${err.message}`);
      return;
    });
    window.alert(res);
  }

  const updateUsersServiceType = async() => {
    const res = await updateUserServiceType().catch((err: Error) => {
      window.alert(`err: ${err.message}`);
      return;
    });
    window.alert(res);
  }
  return(
    <>
    <StyledFullPaper>
    <SubTitle>利用者コピー機能</SubTitle>
    <Caption>{'選択した事業所の利用者の中で直近に作成された利用者情報をコピーします'}</Caption>
     <SingleTextInput
        label={'追加する人数'}
        gridSize={4}
        value={numberOfUser}
        setFunction={(data)=>setNumberOfUser(data)}
      />
     <SelectInput
        label={'コピー先事業所'}
        gridSize={4}
        value={organizationId}
        items={selectItems}
        onChange={(e)=>setOrganizationId(e.target.value)}
     />
     <SelectInput
        label={'コピー元利用者'}
        gridSize={4}
        value={selectedUserId}
        items={userList}
        onChange={(e)=>setSelectedUserId(e.target.value)}
     />
     <BasicButton
       value={'コピーする'}
       onClick={onClickCopyButton}
       disabled={isDisabled}
     />
     </StyledFullPaper>
     <StyledFullPaper>
    <SubTitle>作業記録インポート機能</SubTitle>
    <Caption>{'選択した事業所に選択した利用者の記録が記録されます'}</Caption>
    <SelectInput
          label={'提供サービス'}
          value={serviceType}
          gridSize={3}
          onChange={(e)=>setServiceType(e.target.value as 'aType'|'bType')}
          items={items}
        />
    <SelectInput
        label={'インポート先事業所'}
        gridSize={4}
        value={organizationId}
        items={selectItems}
        onChange={(e)=>setOrganizationId(e.target.value)}
     />
    <div>
      <input type="file"
       id="avatar" name="avatar"
       accept="text/csv"
       onChange={(e)=>onUploadFile(e)}
    />  
    <Caption>{'指定のcsvをアップロードしてください'}</Caption>
    </div>
    <div>
    <DatePicker
          label={'対象年月'}
          openTo={'year'}
          format={'yyyy-MM'}
          views={["year", "month"]}
          value={date}
          gridSize={4}
          onChange={(data, value) => setDate(value as string)}
        />  
    </div>
      <BasicButton
        value={'作業記録をインポート'}
      onClick={upload}
      disabled={isDisabled}
    />
    </StyledFullPaper>
    <StyledFullPaper>
    <SubTitle>組織ステータス追加バッチ</SubTitle>
    <BasicButton
        value={'組織ステータスバッチの実行'}
      onClick={updateOrgStatus}
      disabled={isDisabled}
    />
    <SubTitle>利用者サービスタイプ追加バッチ</SubTitle>
    <BasicButton
        value={'組織ステータスバッチの実行'}
      onClick={updateUsersServiceType}
      disabled={isDisabled}
    />
    </StyledFullPaper>
    </>
  )
}

